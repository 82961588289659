<template>
<main id="main">
  <div class="wrap1160">
  <section id="guide">
    <h1><span>{{ $t("GUIDE_BUY_H1") }}</span></h1>
    <h2>
      <span class="highlight"
        >{{ $t("GUIDE_BUY_H2") }}</span
      >
    </h2>
    <ul class="step">
      <li class="step">
        <h3><em>STEP1</em>{{ $t("GUIDE_BUY_STEP1_TITLE1") }}</h3>
        <div class="conts">
          <div class="outline">
            <div class="out-txt">
              {{ $t("GUIDE_BUY_STEP1_TEXT1") }}
            </div>
          </div>
          <Button :text="$t('GUIDE_NEW_MEMBER_REGISTRATION')" :addClassNameBtn="null" @child-click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)" class="taC btn-signup"/>
        </div>
      </li>
      <li class="step">
        <h3><em>STEP2</em>{{ $t("GUIDE_BUY_STEP2_TITLE1") }}</h3>
        <div class="conts">
          <div class="outline">
            <p class="fontB txtColor">
              {{ $t("GUIDE_BUY_STEP2_TEXT1") }}<br />
              {{ $t("GUIDE_BUY_STEP2_TEXT2") }}
            </p>
          </div>

          <div class="outline">
            <div class="out-txt"></div>
          </div>
          <div class="judge">
            <div class="outline">
              <p>
                {{ $t("GUIDE_BUY_STEP2_TEXT3") }}<br />
                {{ $t("GUIDE_BUY_STEP2_TEXT4") }}
              </p>
            </div>
          </div>
        </div>
      </li>
      <li class="step">
        <h3><em>STEP3</em>{{ $t("GUIDE_BUY_STEP3_TITLE1") }}</h3>
        <div class="conts">
          <div class="judge">
            <div class="outline">
              <div class="out-txt">
                <p class="fontB txtColor">{{ $t("GUIDE_BUY_STEP3_TEXT3") }}</p>
                <p class="mt20">
                  {{ $t("GUIDE_BUY_STEP3_TEXT4") }}<br/>
                  {{ $t("GUIDE_BUY_STEP3_TEXT4__2") }}<br/>
                  {{ $t("GUIDE_BUY_STEP3_TEXT4__3") }}<br/>
                  {{ $t("GUIDE_BUY_STEP3_TEXT4__4") }}<br/>
                  {{ $t("GUIDE_BUY_STEP3_TEXT4__5") }}<br/>
                  {{ $t("GUIDE_BUY_STEP3_TEXT4__6") }}<br/>
                  {{ $t("GUIDE_BUY_STEP3_TEXT4__7") }}
                </p>
              </div>
            </div>
            <div class="outline mt20">
              <div class="out-txt">
                <p class="fontB txtColor">{{ $t("GUIDE_BUY_STEP3_TEXT5") }}</p>
                <p class="mt20">
                  {{ $t("GUIDE_BUY_STEP3_TEXT6") }}<br/>
                  {{ $t("GUIDE_BUY_STEP3_TEXT6__2") }}<br/>
                  {{ $t("GUIDE_BUY_STEP3_TEXT6__3") }}<br/>
                  {{ $t("GUIDE_BUY_STEP3_TEXT6__4") }}<br/>
                  {{ $t("GUIDE_BUY_STEP3_TEXT6__5") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="step">
        <h3><em>STEP4</em>{{ $t("GUIDE_BUY_STEP4_TITLE1") }}</h3>
        <div class="conts">
          <div class="outline">
            <p class="fontB txtColor">
              {{ $t("GUIDE_BUY_STEP4_TEXT1") }}（<a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)"
                >{{ $t("GUIDE_SHIPPING_METHOD") }}</a
              >）<br>
              <span class="txtColor__none">{{ $t("GUIDE_BUY_STEP4_TEXT1__2") }}</span>
            </p>
          </div>
          <div class="judge">
            <div class="outline">
              <div class="out-txt">
                <p>
                  <span class="fontB">{{ $t("GUIDE_BUY_STEP4_TEXT2") }}</span><br />
                  {{ $t("GUIDE_BUY_STEP4_TEXT3") }}<br />
                  <br />
                  <span class="fontB">{{ $t("GUIDE_BUY_STEP4_TEXT4") }}</span><br />
                  {{ $t("GUIDE_BUY_STEP4_TEXT5") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="step">
        <h3>
          <em>STEP5</em>{{ $t("GUIDE_BUY_STEP5_TITLE1") }}<a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)">{{ $t("GUIDE_AUCTION_COMMISSION") }}</a
          >{{ $t("GUIDE_BUY_STEP5_TITLE2") }}
        </h3>
        <div class="conts">
          <div class="outline">
            <div class="out-txt">
              <p>{{ $t("GUIDE_BUY_STEP5_TEXT1") }}</p>
              <p>
                <a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)"
                  >{{ $t("GUIDE_AUCTION_COMMISSION_ALL_SORTS") }}</a
                >
              </p>
              <p>
                <a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)">{{ $t("GUIDE_SHIPPING_METHOD") }}</a>
              </p>
            </div>
          </div>
          <div class="judge">
            <div class="outline">
              <div class="out-txt">
                <p>
                  <span class="fontB">{{ $t("GUIDE_BUY_STEP5_TEXT2") }}</span><br />
                  {{ $t("GUIDE_BUY_STEP5_TEXT3") }}<br />
                  {{ $t("GUIDE_BUY_STEP5_TEXT4") }}<a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)" class="link">{{ $t("GUIDE_BUY_STEP5_TEXT5") }}</a
                  >{{ $t("GUIDE_BUY_STEP5_TEXT6") }}<br />
                  {{ $t("GUIDE_BUY_STEP5_TEXT7") }}
                  <br /><br />
                  <span class="fontB">{{ $t("GUIDE_BUY_STEP5_TEXT8") }}</span><br />
                  {{ $t("GUIDE_BUY_STEP5_TEXT9") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="step">
        <h3>
          <em>STEP6</em
          >{{ $t("GUIDE_BUY_STEP6_TITLE1") }}
        </h3>
        <div class="conts">
          <div class="outline">
            <div class="out-txt">
              <p>
                {{ $t("GUIDE_BUY_STEP6_TEXT1") }}<span class="mark"
                  >{{ $t("GUIDE_BUY_STEP6_TEXT2") }}</span
                >{{ $t("GUIDE_BUY_STEP6_TEXT3") }}<br />
                {{ $t("GUIDE_BUY_STEP6_TEXT4") }}<br />
                <span class="mark"
                  >{{ $t("GUIDE_BUY_STEP6_TEXT5") }}</span
                ><br />
                {{ $t("GUIDE_BUY_STEP6_TEXT6") }}<br />
                {{ $t("GUIDE_BUY_STEP6_TEXT7") }}<br />
                {{ $t("GUIDE_BUY_STEP6_TEXT8") }}<br />
                {{ $t("GUIDE_BUY_STEP6_TEXT9") }}
              </p>
            </div>
          </div>
        </div>
      </li>
      <li class="step">
        <h3><em>STEP7</em>{{ $t("GUIDE_BUY_STEP7_TITLE1") }}</h3>
        <div class="conts">
          <div class="outline">
            <div class="out-txt">
              <p>
                <a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)" class="link"
                  >{{ $t("GUIDE_BUY_STEP7_TEXT1") }}</a
                >
              </p>
              <p>
                {{ $t("GUIDE_BUY_STEP7_TEXT2") }}<br />
                {{ $t("GUIDE_BUY_STEP7_TEXT3") }}<br />
                <span class="highlight">
                  {{ $t("GUIDE_BUY_STEP7_TEXT4") }}<br />
                  {{ $t("GUIDE_BUY_STEP7_TEXT5") }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </li>
      <li class="step">
        <h3><em>STEP8</em>{{ $t("GUIDE_BUY_STEP8_TITLE1") }}</h3>
        <div class="conts">
          <div class="outline">
            <div class="out-txt">
              <p>
                {{ $t("GUIDE_BUY_STEP8_TEXT1") }}<br />
                {{ $t("GUIDE_BUY_STEP8_TEXT2") }}<br />
              </p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>
  </div>
</main>
</template>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
<style scoped lang="sass" src="@/assets/scss/guide.sass"></style>
<style scoped lang="sass" src="@/assets/scss/other.sass"></style>

<style scoped lang="scss">
.txtColor {
  &__none {
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>

<script>
import Button from '@/components/Guide/Parts/Button';

export default {
  components : {
    Button,
  }
}
</script>
